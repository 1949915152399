const defaultState = {
	tinType: 'Nein',
	germanTin: null,
	fatcaCountry: null,
	taxIdentificationNumber: null,
	taxIdentificationNumberReason: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_TIN_TYPE':
			return { ...state, tinType: action.payload };
		case 'SET_GERMAN_TIN':
			return { ...state, germanTin: action.payload };
		case 'SET_FATCA_COUNTRY':
			return { ...state, fatcaCountry: action.payload };
		case 'SET_TAX_IDENTIFICATION_NUMBER':
			return { ...state, taxIdentificationNumber: action.payload };
		case 'SET_TAX_IDENTIFICATION_NUMBER_REASON':
			return { ...state, taxIdentificationNumberReason: action.payload };
		default:
			return state;
	}
};

export default reducers;
