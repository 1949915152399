import React, { useEffect, useRef } from 'react';
import C from 'constants/Constants';
import { PROTECT_MAX_AGE_CREDIT } from 'constants/Credit';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useDateUtils } from 'utils/date/date';
import { usePpiUtils } from 'utils/ppi/ppi';

import { Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import Alert from 'components/Atoms/Alert/Alert';
import BoxCollapsible from 'components/Molecules/Box/BoxCollapsible/BoxCollapsible';
import SvgInsurance from 'components/Atoms/SVG/Icons/SvgInsurance';
import SvgScales from 'components/Atoms/SVG/Icons/SvgScales';
import Overview from 'components/Pages/Insurance/Overview/Overview';
import PPIBody from 'components/Pages/Insurance/PPI/PPI';
import ProtectBody from 'components/Pages/Insurance/Protect/Protect';

import { useDispatch, useSelector } from 'react-redux';
import { setProtectRuntime, setProtectMonthlyRate, setProtect } from 'store/Insurance/actions';

const Insurance = () => {
	const { getCurrentFlow, isCreditSubType, isCreditCardSubType } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid, ecommerceAddToCart, ecommerceRemoveFromCart } = useTrackingUtils();
	const { hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { getAge } = useDateUtils();
	const { showPpiPage } = usePpiUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeHasPpi = useSelector((state => state.insurance.hasPpi));
	const storePpiAlreadyExists = useSelector((state => state.insurance.ppiAlreadyExists));
	const storeProtect = useSelector((state => state.insurance.protect));

	const storeBirthdate = useSelector((state => state.birthdate.birthdate));

	const age = getAge(storeBirthdate);

	const protectAnchorRef = useRef(null);

	useEffect(() => {
		if (!showPpiPage()) {
			goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
		}
	}, []);

	useEffect(() => {
		if (!storeHasPpi) {
			dispatch(setProtect(null));
			dispatch(setProtectRuntime(null));
			dispatch(setProtectMonthlyRate(null));
		}
	}, [storeHasPpi]);

	const clearErrors = () => {};

	const validateFields = async () => {
		let hasEmptyFields = false;

		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();

		if (storeHasPpi) {
			ecommerceAddToCart(true, 'ppi');
		} else {
			ecommerceRemoveFromCart('ppi');
		}

		if (storeProtect && (storeProtect === 'standard' || storeProtect === 'premium' || storeProtect === 'basic')) {
			ecommerceAddToCart(true, 'protect');
		} else {
			ecommerceRemoveFromCart('protect');
		}

		goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const onPrevButton = () => {
		if (isCreditSubType()) {
			goToPage(currentFlow + C.ROUTES.EXTERNAL_CREDIT);
		} else if (currentFlow === C.FRONTEND.VISACARD) {
			goToPage(currentFlow + C.ROUTES.BANK_ACCOUNT);
		} else if (isCreditCardSubType()) {
			goToPage(currentFlow + C.ROUTES.FINANCIAL_STATUS);
		}
	};

	return (
		<>
			<Title mb>{m('insurance.title', currentFlow)}</Title>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={8}
				onPrevButton={onPrevButton}
			>
				{isCreditSubType() && <Overview />}
				<BoxCollapsible
					icon={<SvgInsurance />}
					title={m('insurance.ppi.title', currentFlow)}
					subtitle={m('insurance.ppi.subtitle', currentFlow)}
					contentHeader={storePpiAlreadyExists ? <Alert type="warning" messages={[{ message: m('insurance.ppi.alreadyExists', currentFlow) }]} removeMarginBottom /> : null}
					contentBody={<PPIBody protectAnchorRef={protectAnchorRef} />}
					showOnMount
				/>
				<div ref={protectAnchorRef} />
				{isCreditSubType() && storeHasPpi && age < PROTECT_MAX_AGE_CREDIT && (
					<BoxCollapsible
						icon={<SvgScales />}
						title={m('insurance.protect.title', currentFlow)}
						subtitle={m('insurance.protect.subtitle', currentFlow)}
						contentBody={<ProtectBody />}
						showOnMount
					/>
				)}
			</Form>
		</>
	);
};
export default Insurance;
