import React, { useState } from 'react';
import classNames from 'classnames';
import C from 'constants/Constants';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { C as Autocomplete } from 'constants/enums/Autocomplete';
import { EMAIL_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useFlowUtils } from 'utils/flow/flow';
import { useRegistrationApi } from 'api/registration';

import Form from 'components/Molecules/Form/Form';
import { Link, Text, Title } from 'components/Atoms/Text';
import { CheckboxInput, TextInput } from 'components/Atoms/Form';
import Button from 'components/Atoms/Button/Button';
import Modal from 'components/Atoms/Modal/Modal';
import AllowAdvertisingModalContent
	from 'components/Atoms/Partials/Content/AllowAdvertisingModalContent/AllowAdvertisingModalContent';
import SvgEmailCheckLink from 'components/Atoms/SVG/Icons/SvgEmailCheckLink';
import SvgEmailResendLink from 'components/Atoms/SVG/Icons/SvgEmailResendLink';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setEmail } from 'store/Email/actions';
import { setAllowAdvertising } from 'store/Consent/actions';

import styles from './Email.module.scss';

const Email = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, hasEmailError, addErrorMessage, showDefaultConnectionError } = useValidationUtils();
	const { postRegistration, patchResendInvitationEmail, postLogin } = useRegistrationApi();
	const { isCreditErgoSanc, isCreditAuxMoneySanc, isGiroErgoUid, gmkHyundaiMsgPrefix } = useFlowUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeEmail = useSelector((state => state.email.email));
	const storeAllowAdvertising = useSelector((state => state.consent.allowAdvertising));

	const [showResendEmail, setShowResendEmail] = useState(false);
	const [showResendEmailSuccess, setShowResendEmailSuccess] = useState(false);
	const [popUpAlreadyExistsIsOpen, setPopUpAlreadyExistsIsOpen] = useState(false);
	const [popUpAllowAdvertisingIsOpen, setPopUpAllowAdvertisingIsOpen] = useState(false);

	const [emailError, setEmailError] = useState(null);
	const [allowAdvertisingError, setAllowAdvertisingError] = useState(null);

	const clearErrors = () => {
		setEmailError(null);
		setAllowAdvertisingError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isValidEmail = true;

		if (isEmpty(storeEmail)) {
			setEmailError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else {
			isValidEmail = !hasEmailError(storeEmail);
			setEmailError(!isValidEmail ? m('validation.error.invalid', 'fields') : null);
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields && isValidEmail;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postRegistration(
				() => {
					continueValid();
					goToPage(currentFlow + C.ROUTES.EMAIL_SENT);
				},
				(statusCode) => {
					continueInvalid();
					switch (statusCode) {
						case 406:
							addErrorMessage(
								FormErrorCode.REGISTERED_BUT_NOT_VERIFIED,
								m('validation.error.registeredButNotVerified', 'fields')
							);
							setShowResendEmail(true);
							break;
						case 409:
							setPopUpAlreadyExistsIsOpen(true);
							break;
						default:
							showDefaultConnectionError();
							break;
					}
				}
			);
		} else {
			onInvalid();
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const resendEmail = async () => {
		await patchResendInvitationEmail(
			() => {
				setShowResendEmailSuccess(true);
			},
			() => {}
		);
	};

	const login = async () => {
		setPopUpAlreadyExistsIsOpen(false);
		await postLogin(
			storeEmail,
			() => {
				continueValid();
			},
			(status) => {
				if (status === 428) {
					continueValid();
					goToPage(currentFlow + C.ROUTES.EMAIL_SENT);
				} else {
					continueInvalid();
				}
			}
		);
	};

	const autofill = () => {
		dispatch(setEmail('niefilet@santander.de'));
		dispatch(setAllowAdvertising(false));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.email.title', 'global')}</Title>
			<Text size="l" mb>{m(`${gmkHyundaiMsgPrefix}email.subtitle`, currentFlow)}</Text>

			{showResendEmail && (
				<div className="text-align-right mb--10">
					<Text>
						{showResendEmailSuccess ? (
							<span className={classNames(['link primary', styles.resendLinkSuccess])}>
								<SvgEmailCheckLink />
								{m('pages.email.resendEmail.success', 'global')}
							</span>
						) : (
							<Link onClick={resendEmail}>
								<SvgEmailResendLink />
								{m('pages.email.resendEmail.link', 'global')}
							</Link>
						)}
					</Text>
				</div>
			)}

			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={3}
				onPrevButton={() => { goToPage(currentFlow + C.ROUTES.BIRTHDATE); }}
			>
				<TextInput
					value={storeEmail}
					setValue={(value) => { dispatch(setEmail(value)); }}
					label={m('email.label', 'fields')}
					hasError={!!emailError}
					message={emailError}
					maxLength={50}
					regex={EMAIL_INPUT}
					autoComplete={Autocomplete.EMAIL}
					tabindex={1}
					testId="email"
				/>

				{!isCreditErgoSanc && !isCreditAuxMoneySanc && !isGiroErgoUid && (
					<CheckboxInput
						value={storeAllowAdvertising}
						setValue={(value) => { dispatch(setAllowAdvertising(value)); }}
						label={
							<>
								{m('allowAdvertising.description', 'fields')}{' '}
								<Link
									onClick={() => {
										setPopUpAllowAdvertisingIsOpen(true);
									}}
								>
									{m('allowAdvertising.linkText', 'fields')}
								</Link>
							</>
						}
						hasError={!!allowAdvertisingError}
						message={allowAdvertisingError}
						tabindex={2}
						testId="allow-advertising"
					/>
				)}
			</Form>

			<Modal
				isOpen={popUpAllowAdvertisingIsOpen}
				onClose={() => {
					setPopUpAllowAdvertisingIsOpen(false);
				}}
			>
				<AllowAdvertisingModalContent />
			</Modal>

			<Modal
				isOpen={popUpAlreadyExistsIsOpen}
				onClose={() => {
					setPopUpAlreadyExistsIsOpen(false);
				}}
				smallWidth
			>
				<Text mb>{m('alreadyExistsPopup.text', 'fields')}</Text>
				<div className="text-align-center">
					<Button
						onClick={login}
						fullWidthMobile
					>
						{m('alreadyExistsPopup.submit', 'fields')}
					</Button>
				</div>
			</Modal>
		</>
	);
};
export default Email;
