const defaultState = {
	maritalStatus: 'SINGLE',
	numberOfAdults: 1,
	numberOfChildren: 0,
	numberOfChildrenWithBenefit: 0
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_MARITAL_STATUS':
			return { ...state, maritalStatus: action.payload };
		case 'SET_NUMBER_OF_ADULTS':
			return { ...state, numberOfAdults:
					(action.payload || action.payload === 0) && !isNaN(action.payload)
						? parseInt(action.payload + '')
						: 1
			};
		case 'SET_NUMBER_OF_CHILDREN':
			return { ...state, numberOfChildren:
					(action.payload || action.payload === 0) && !isNaN(action.payload)
						? parseInt(action.payload + '')
						: null
			};
		case 'SET_NUMBER_OF_CHILDREN_WITH_BENEFIT':
			return { ...state, numberOfChildrenWithBenefit:
					(action.payload || action.payload === 0) && !isNaN(action.payload)
						? parseInt(action.payload + '')
						: 0
			};
		default:
			return state;
	}
};

export default reducers;
