import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import C from 'constants/Constants';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { IBAN_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { usePageUtils } from 'utils/page/page';
import { useIbanUtils } from 'utils/iban/iban';
import { useApplicationApi } from 'api/application';

import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { RadioButtonInput, TextInput } from 'components/Atoms/Form';
import { Col, Row } from 'components/Atoms/Grid';
import PopupIcon from 'components/Atoms/PopupIcon/PopupIcon';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setReferenceIban, setReferenceIbanSantander } from 'store/BankAccount/actions';

const ClearingAccount = () => {
	const { getCurrentFlow } = useUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, addErrorMessage, hasIbanError } = useValidationUtils();
	const { continueInvalid, continueValid, ecommerceAddPaymentInfo } = useTrackingUtils();
	const { goToPage } = usePageUtils();
	const { ibanContainsSantanderBlz, formatIbanForInput, isGermanIban } = useIbanUtils();
	const { patchApplicationDepotUpdateIban } = useApplicationApi();

	const currentFlow = getCurrentFlow();

	const location = useLocation();
	const isRetry = location.pathname.endsWith(C.ROUTES.CLEARING_ACCOUNT_ERROR);

	const dispatch = useDispatch();
	const storeReferenceIban = useSelector((state => state.bankAccount.referenceIban));
	const storeReferenceIbanSantander = useSelector((state => state.bankAccount.referenceIbanSantander));

	const [useSantanderIban, setUseSantanderIban] = useState(storeReferenceIbanSantander ? 'true' : (storeReferenceIban ? 'false' : 'true'));

	const useSantanderIbanItems = [
		{ label: isRetry ? m('clearingAccountError.checkIban', currentFlow) : m('yes', 'fields'), value: 'true' },
		{ label: isRetry ? m('clearingAccountError.openInstantAccessSavingsAccount', currentFlow) : m('no', 'fields'), value: 'false' }
	];

	const [useSantanderIbanError, setUseSantanderIbanError] = useState(null);
	const [referenceIbanError, setReferenceIbanError] = useState(null);
	const [referenceIbanSantanderError, setReferenceIbanSantanderError] = useState(null);

	useEffect(() => {
		if (isRetry) {
			setUseSantanderIban(null);
			dispatch(setReferenceIban(null));
			dispatch(setReferenceIbanSantander(null));
		}
	}, []);

	useEffect(() => {
		if (useSantanderIban === 'true') {
			dispatch(setReferenceIban(null));
		} else if (useSantanderIban === 'false') {
			dispatch(setReferenceIbanSantander(null));
		} else {
			dispatch(setReferenceIban(null));
			dispatch(setReferenceIbanSantander(null));
		}
	}, [useSantanderIban]);

	const clearErrors = () => {
		setUseSantanderIbanError(null);
		setReferenceIbanError(null);
		setReferenceIbanSantanderError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isIbanValid = true;

		let iban = useSantanderIban === 'true' ? storeReferenceIbanSantander : storeReferenceIban;

		if (isEmpty(useSantanderIban)) {
			setUseSantanderIbanError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}

		if (isEmpty(iban)) {
			if (useSantanderIban === 'true') {
				setReferenceIbanSantanderError(m('validation.error.required', 'fields'));
			} else {
				setReferenceIbanError(m('validation.error.required', 'fields'));
			}
			hasEmptyFields = true;
		} else {
			isIbanValid = !(hasIbanError(iban));
			if (!isIbanValid) {
				if (useSantanderIban === 'true') {
					setReferenceIbanSantanderError(m('validation.error.invalid', 'fields'));
				} else {
					setReferenceIbanError(m('validation.error.invalid', 'fields'));
				}
			} else {
				if (useSantanderIban === 'true') {
					const isSantanderIban = ibanContainsSantanderBlz(iban);
					if (!isSantanderIban) {
						isIbanValid = false;
						setReferenceIbanSantanderError(m('validation.error.invalid', 'fields'));
						addErrorMessage(FormErrorCode.IBAN, m('validation.error.iban.notASantanderBlz', 'fields'));
					}
				} else {
					const isGermanIbanValid = isGermanIban(iban);
					if (!isGermanIbanValid) {
						isIbanValid = false;
						setReferenceIbanError(m('validation.error.invalid', 'fields'));
						addErrorMessage(FormErrorCode.IBAN, m('validation.error.iban.notGerman', 'fields'));
					}
				}
			}
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields && isIbanValid;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			if (isRetry) {
				await patchApplicationDepotUpdateIban(
					storeReferenceIbanSantander,
					storeReferenceIban,
					() => {
						continueValid();
						goToPage(currentFlow + C.ROUTES.DEPOT_PURCHASE_SUCCESS);
					},
					(statusCode) => {
						continueInvalid();
						let nextPage = C.ROUTES.THANKYOU;
						if (statusCode === 400) {
							nextPage = C.ROUTES.CLEARING_ACCOUNT_ERROR;
						}
						goToPage(currentFlow + nextPage);
					}
				);
			} else {
				onValid();
			}
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		ecommerceAddPaymentInfo();
		goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		setUseSantanderIban('false');
		dispatch(setReferenceIban(C.DUMMY_IBAN_2));
		dispatch(setReferenceIbanSantander(null));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Row>
				<Col grow>
					<Title mb>{isRetry ? m('clearingAccountError.title', currentFlow) : m('clearingAccount.title', currentFlow)}</Title>
				</Col>
				<Col noGrow>
					<PopupIcon
						content={
							<>
								<Title size="m" mb>{m('clearingAccount.infoTitle', currentFlow)}</Title>
								<Text>
									{
										m(
											'clearingAccount.infoText',
											currentFlow,
											null,
											true
										)
									}
								</Text>
							</>
						}
						inTitleXl
					/>
				</Col>
			</Row>
			{isRetry && <Text mb>{m('clearingAccountError.subtitle', currentFlow)}</Text>}
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={4}
				onPrevButton={
					!isRetry ? () => { goToPage(currentFlow + C.ROUTES.STOCK_PROFICIENCY); } : null
				}
			>
				<RadioButtonInput
					value={useSantanderIban !== null ? useSantanderIban : null}
					setValue={(value) => { setUseSantanderIban(value); }}
					items={useSantanderIbanItems}
					hasError={!!useSantanderIbanError}
					message={useSantanderIbanError}
					btnStyle="switch"
					tabindex={1}
					testId="use-santander-iban"
				/>
				{!isRetry && useSantanderIban && <Text mb>{m('clearingAccount.subtitle', currentFlow)}</Text>}
				{useSantanderIban === 'true' && (
					<>
						<Text mb>{m('clearingAccount.yesInputLabel', currentFlow)}</Text>
						<TextInput
							value={storeReferenceIbanSantander}
							setValue={(value) => { dispatch(setReferenceIbanSantander(value)); }}
							label={m('referenceIban.label', 'fields')}
							hasError={!!referenceIbanSantanderError}
							message={referenceIbanSantanderError ?? m('iban.placeholder', 'fields')}
							regex={IBAN_INPUT}
							format={formatIbanForInput}
							maxLength={40}
							tabindex={2}
							testId="reference-iban-santander"
						/>
					</>
				)}
				{useSantanderIban === 'false' && (
					<>
						<Text mb>{m('clearingAccount.noInputLabel', currentFlow, null, true)}</Text>
						<TextInput
							value={storeReferenceIban}
							setValue={(value) => { dispatch(setReferenceIban(value)); }}
							label={m('iban.label', 'fields')}
							hasError={!!referenceIbanError}
							message={referenceIbanError ?? m('iban.placeholder', 'fields')}
							regex={IBAN_INPUT}
							format={formatIbanForInput}
							maxLength={40}
							tabindex={3}
							testId="reference-iban"
						/>
					</>
				)}
			</Form>
		</>
	);
};
export default ClearingAccount;
