module.exports = {
	title: 'Wertpapierdepot',
	progressBar: {
		step1: 'Ihre Angaben',
		step2: 'Ihre Person',
		step3: 'Antrag senden',
		subline: {
			letsGo: "<strong>Los geht's:</strong> In nur {minutes} Minuten bis zur Depoteröffnung."
		}
	},
	birthdate: {
		subtitle: 'Für die Depoteröffnung müssen Sie volljährig sein.'
	},
	email: {
		subtitle:
			'Ihre E-Mail-Adresse wird benötigt, damit Sie Ihr Depot eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	stockProficiency: {
		title: 'Haben Sie Kenntnisse und Erfahrungen mit Wertpapiergeschäften?',
		subtitle: 'Für die Durchführung von Wertpapierdienstleistungen gemäß Wertpapierhandelsgesetz benötigen wir Angaben zu Ihren Kenntnissen und Erfahrungen mit Wertpapieren.',
		proficiency: 'Kenntnisse',
		experiences: 'Erfahrungen',
		infoTitle: 'Angaben zu Kenntnissen und Erfahrungen',
		infoText: 'Bei der Durchführung von Wertpapierdienstleistungen sind wir gesetzlich verpflichtet von unseren Kunden Angaben über deren Erfahrungen und Kenntnisse in derartigen Geschäften zu erfragen. Die Erteilung der Angaben ist freiwillig und liegt in Ihrem eigenen Interesse. Wichtig sind vollständige und zutreffende Informationen. Mit diesen Angaben prüfen wir, ob das gewünschte Finanzinstrument für Sie angemessen ist und wir führen zugleich einen Zielmarktabgleich für Sie durch.<br /><br />Falls Sie diese Angaben nicht machen möchten, ist eine Depoteröffnung online leider nicht möglich. Wenden Sie sich in diesem Fall bitte persönlich an eine <a class="link primary" href="https://service.santander.de/webapps/apps/filialen?solo=1" target="_blank">Santander-Filiale</a> oder eröffnen Sie das gewünschte Wertpapierdepot per Videoberatung über die Santander Direktberatung (Tel.: 02161 906 0187, Mo-Fr. 8:00-19:00 Uhr).',
		bonds: 'Anleihen, Renten-, Geldmarkt- und Garantiefonds',
		stocks: 'Aktien, Aktienfonds',
		fundOfFunds: 'Dach- und Mischfonds',
		derivates: 'Derivative Finanzinstrumente (z.B. Optionen, Futures und Swaps)',
		certificates: 'Zertifikate (inklusive Knock-Out)',
		openPropertyFunds: 'Offene Immobilienfonds',


		BONDS: 'Anleihen, Renten-, Geldmarkt- und Garantiefonds',
		STOCKS: 'Aktien, Aktienfonds',
		FUND_OF_FUNDS: 'Dach- und Mischfonds',
		DERIVATIVES:
			'Derivative Finanzinstrumente (z.B. Optionen, Futures und Swaps)',
		CERTIFICATES: 'Zertifikate (inklusive Knock-Out)',
		OPEN_PROPERTY_FUNDS: 'Offene Immobilienfonds',
		TEST: 'Test'
	},
	nationalId: {
		title: 'Wie lautet Ihre National-ID?',
		subtitle: 'Gemäß MiFID II /MiFIR Bestimmungen (03. Januar 2018) benötigen wir Ihre persönliche National-ID',
		headline: 'Was ist die National-ID?',
		linkText: 'Übersicht der Nationalen Identifikationsmerkmale',
		link:
			'https://www.santander.de/content/pdf/informationen/nationale_identifikationsmerkmale.pdf',
		content:
			'Alle Wertpapierfirmen/Depotbanken sind zur Meldung von Geschäften mit Finanzinstrumenten gesetzlich verpflichtet. Für diese Transaktionsmeldungen gem. Art. 26 MiFIR benötigen wir Ihre persönliche National-ID. Gerne stellen wir Ihnen die Übersicht der Nationalen Identifikationsmerkmale zur Verfügung. In dieser Tabelle finden Sie das länderspezifische Format und die entsprechende Quelle Ihres Identifikationsmerkmals.'
	},
	clearingAccount: {
		title: 'Haben Sie ein Santander Konto?',
		subtitle:
			'Für die Eröffnung des Wertpapierdepots benötigen Sie ein Santander Konto (Giro- oder Tagesgeldkonto).',
		infoTitle: 'Was ist ein Depotverrechnungskonto?',
		infoText:
			'Für alle Abrechnungen im Zusammenhang mit Ihrem Depot ist ein Verrechnungskonto bei Santander erforderlich.</p><br/><p>Sie können selbst entscheiden, welches Santander Konto (Giro- oder Tagesgeldkonto) Sie mit Ihrem Wertpapierdepot verknüpfen möchten. Bitte beachten Sie aber in diesem Zusammenhang, dass der Kontoinhaber mit dem Depotinhaber identisch sein muss und Kontoverbindungen bei Fremdbanken nicht zulässig sind. Zusätzlich können nur Einzelkonten und keine Gemeinschaftskonten verwendet werden.',
		yesInputLabel:
			'Geben Sie bitte Ihre IBAN an, wenn Sie das bestehende Konto als Verrechnungskonto für Ihr neues Depot verwenden möchten. Mit dem Klicken auf "Nein" erstellen wir für Sie ein Tagesgeldkonto. Bitte beachten Sie, dass Sie Inhaber des angegebenen Kontos sein müssen und dieses kein Gemeinschaftskonto sein darf.',
		noInputLabel:
			'Gerne richten wir Ihnen ein kostenloses Tagesgeldkonto ein, die Bedingungen finden Sie <a class="link primary" target="_blank" href="https://www.santander.de/privatkunden/sparen-anlegen/tagesgeld/">hier</a>. Geben Sie hier die IBAN des Girokontos an, auf das zukünftig Überweisungen vom Tagesgeldkonto erfolgen sollen.'
	},
	clearingAccountError: {
		title:
			'Achtung! Das angegebene Verrechnungskonto kann nicht verwendet werden',
		subtitle:
			'Bitte überprüfen Sie die angegebene IBAN oder geben Sie ein Referenzkonto zur Erstellung eines kostenlosen Tagesgeldkontos an.',
		checkIban: 'IBAN überprüfen',
		openInstantAccessSavingsAccount: 'Tagesgeldkonto eröffnen',
		errorText1002:
			'Bitte beachten Sie, dass Sie Inhaber des Verrechnungskontos sein müssen und das Verrechnungskonto kein Gemeinschaftskonto sein darf.',
		errorText1003: 'Das Produkt ist nicht als Verrechnungskonto gültig.',
		errorText1004: 'Ein Fehler ist aufgetreten.'
	},
	termsAndConditions: {
		acceptTermsAndConditions: {
			descriptionStart: 'Ich habe die ',
			descriptionLink:
				'Rahmenvereinbarung über Geschäfte in Finanzinstrumenten und die dazugehörigen vorvertraglichen Informationen ',
			description:
				'sowie die produktspezifischen Bedingungen (Allgemeine Informationen zur Santander Consumer Bank AG, Information über den Umgang mit Interessenskonflikten, Sonderbedingungen für Wertpapiergeschäfte, Allgemeine Grundsätze der Auftragsausführung), die Datenschutzhinweise, die Werbehinweise, den Informationsbogen für Einleger, die AGB und das Preis- und Leistungsverzeichnis erhalten und lege diese meinem Antrag zugrunde.',
			linkText: 'Download der Bedingungen und Informationen'
		},
		hint: '<strong>Hinweis:</strong> Sie beantragen zugleich ein kostenfreies Tagesgeldkonto, falls Sie nicht ein bestehendes Santander-Konto als Depotverrechnungskonto ausgewählt haben.',
		summary:
			'Mit dem Anklicken des Buttons "Jetzt eröffnen" beauftragen Sie Santander, für Sie ein Wertpapierdepot zu eröffnen. Zusätzlich beauftragen Sie die Santander mit der Eröffnung eines kostenfreien Tagesgeldkontos, falls Sie nicht ein bestehendes Santander-Konto als Depotverrechnungskonto ausgewählt haben. Weitere verbundene Services (z.B. Einrichtung des Zugangs zum Online Banking) werden wir für Sie automatisch ausführen. Des Weiteren beauftragen Sie uns ausdrücklich alle Dokumente das Wertpapierdepot betreffend an Ihre Postbox einzustellen. Wenn Sie abweichend hierzu den portopflichtigen Versand per Post wählen möchten, ist dies online leider nicht möglich. Bitte wenden Sie sich in diesem Fall an die Santander Direktberatung (Tel.: 02161 906 0187, Mo-Fr. 8:00-19:00 Uhr) oder eine <a class="link primary" href="https://service.santander.de/webapps/apps/filialen?solo=1" target="_blank">Santander-Filiale</a>. Santander wird Ihren Antrag annehmen, wenn Sie sich vollständig legitimiert haben (Identitätsüberprüfung per VideoIdent). Über die Annahme informiert Santander Sie gesondert in Textform.',
		submit: 'Jetzt Antrag senden'
	},
	identification: {
		title: 'Ihr letzter Schritt bis zur Depoteröffnung: Jetzt identifizieren',
		subtitle:
			'Mit dem Ident Verfahren unseres Partners WebID Solutions können Sie sicher, einfach und bequem Ihre Identität bestätigen.',
		kontoIdent: {
			title: 'Konto-Ident',
			subtitle: 'Identifizieren mit einer Überweisung von 1 Cent',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<ol><li>Fotos von Ausweis und Gesicht machen</li><li>Log-in ins Online-Banking, Überweisung von 1 Cent bestätigen</li><li>TAN-Eingabe</li></ol>'
		},
		videoIdent: {
			title: 'Video-Ident',
			subtitle: 'Videogespräch mit einem WebID-Agenten',
			list:
				'<li>Kurze Wartezeit</li><li>Von 7:00 bis 22:00 Uhr von überall möglich</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<ol><li>Ausweis bereit halten</li><li>Videocall mit WebID-Agenten durchführen</li><li>TAN-Eingabe</li></ol>'
		}
	},
	download: {
		contract: {
			application: 'Wertpapierdepotantrag herunterladen',
			pdftitle: 'Wertpapierdepotantrag.pdf'
		}
	},
	addressVerification: {
		title: 'Bitte bestätigen Sie Ihre Adresse.',
		subtitle:
			'Um den Vorgang abzuschließen benötigen wir von Ihnen noch eines der folgenden Dokumente: Meldebescheinigung, oder eine Rechnung für Telefon, Strom oder Gas.',
		submitExtension1: 'Kein Dokument zur Hand?',
		submitExtension2: 'Später hochladen'
	},
	addressSelection: {
		title: 'Welche Adresse ist korrekt?',
		subtitle:
			'Die von Ihnen angegebene Adresse stimmt nicht mit der Adresse aus dem Video-Ident Verfahren überein. Welche Adresse ist aktueller?',
		items: {
			webIdAddress: {
				title: 'Adresse in Ihrem Ausweis',
				subtitle: 'Bereits bestätigt. Direkte Depoteröffnung.'
			},
			originalAddress: {
				title: 'Angegebene Adresse',
				subtitle: 'Erfordert einen weiteren Nachweis.'
			}
		}
	},
	addressVerificationPostponed: {
		title:
			'Wir haben Ihnen eine E-Mail geschickt. So können Sie jederzeit zurückkehren.',
		subtitle:
			'Wir können Ihren Antrag erst dann bearbeiten, wenn Sie alle benötigten Dokumente vollständig hochgeladen haben.'
	},
	thankYou: {
		title:
			'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.'
	},
	depotPurchaseSuccess: {
		title: 'Herzlichen Glückwunsch zu Ihrem neuen Santander Wertpapierdepot.',
		depotnumber: 'Ihre Depotnummer:',
		bankCode: 'Bankleitzahl:',
		clearingaccountnumber: 'Verrechnungskontonummer:',
		infoText:
			'Möchten Sie vorhandene Wertpapiere an Ihr neues Santander Depot übertragen? Jetzt zum <a class="link primary" href="https://www.santander.de/content/pdf/formulare/auftrag-wertpapier-depoteinzug.pdf" target="_blank">Depoteinzugsformular</a>.',
		downloadButtonText: 'Vertragsdokumente herunterladen',
		closeButtonText: 'Sicher schliessen'
	},
	exitPopup: {
		title: 'Wertpapierdepot Antrag',
		subhead: 'Gehen Sie noch nicht!',
		description: 'Ihr Wertpapierdepot für Einsteiger und Selbstentscheider.',
		continueButton: 'Fortfahren'
	}
};
