export const defaultState = {
	occupation: null,
	occupationMonth: null,
	occupationYear: null,
	occupationLimited: 'Unbefristet',
	occupationLimitedMonth: null,
	occupationLimitedYear: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_OCCUPATION':
			return { ...state, occupation: action.payload };
		case 'SET_OCCUPATION_MONTH':
			return { ...state, occupationMonth: action.payload };
		case 'SET_OCCUPATION_YEAR':
			return { ...state, occupationYear: action.payload };
		case 'SET_OCCUPATION_LIMITED':
			return { ...state, occupationLimited: action.payload };
		case 'SET_OCCUPATION_LIMITED_MONTH':
			return { ...state, occupationLimitedMonth: action.payload };
		case 'SET_OCCUPATION_LIMITED_YEAR':
			return { ...state, occupationLimitedYear: action.payload };
		default:
			return state;
	}
};

export default reducers;
