module.exports = {
	title: 'BestCredit',
	progressBar: {
		step1: 'Persönliche Angaben',
		step2: 'Absicherung',
		step3: 'Antrag senden',
		subline: {
			letsGo: "<strong>Los geht's:</strong> In nur {minutes} Minuten zu Ihrem BestCredit Angebot."
		}
	},
	name: {
		neededUtilsAndDocuments: {
			items: {
				iban: 'IBAN Ihres Girokontos'
			}
		},
		request: 'Kreditanfrage starten'
	},
	birthdate: {
		subtitle: 'Für die Beantragung des Kredits müssen Sie volljährig sein.'
	},
	email: {
		subtitle:
			'Ihre E-Mail wird benötigt, damit Sie den BestCredit digital beantragen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	bankAccount: {
		title: 'Bankverbindung Gehaltskonto',
		subtitle:
			'Von diesem Konto erfolgt auch die Abbuchung Ihrer monatlichen Rate.'
	},
	insurance: {
		title: 'Absicherung',
		ppi: {
			title: 'Kreditraten absichern',
			subtitle: 'Wir empfehlen Ihnen den Abschluss der RSV Plus, um die Rückzahlung Ihrer Kreditraten auch im Ernstfall abzusichern.',
			body: {
				title: 'Ratenschutzversicherung',
				death: {
					title: 'Ratenschutzversicherung: Todesfall',
					tarif_1_1:
						'Ihre Kreditraten werden im Falle der Arbeitsunfähigkeit, unverschuldeter Arbeitslosigkeit und Tod übernommen',
					tarif_1_2:
						'Ihre Kreditraten werden im Falle der Arbeitsunfähigkeit, schweren Krankheiten und Tod übernommen',
					tarif_1_3:
						'Ihre Kreditraten werden im Falle der Arbeitsunfähigkeit, unverschuldeter Arbeitslosigkeit oder schweren Krankheiten und Tod übernommen',
					tarif_23: 'Der ausstehende Darlehensbetrag wird im Todesfall übernommen'
				},
				noPpi: {
					label: 'Ich will die Rückzahlung meiner Raten nicht absichern'
				}
			},
			noPopup: {
				title: 'Keine Ratenschutzversicherung',
				description:
					'Die Ratenschutzversicherung ist nicht Voraussetzung für die Gewährung des Darlehens, bietet Ihnen und Ihrer Familie jedoch im Falle des Todes (und ggf. der Arbeitsunfähigkeit sowie der Arbeitslosigkeit) ein hohes Maß an Sicherheit vor finanziellen Belastungen.<br/><br/>Sollte Sie keine weitere Absicherung haben und auch keine Absicherung wünschen, tragen Sie auch bei Eintreten unvorhersehbarer Ereignisse weiterhin alle Kosten selber. Bei weiteren Fragen können Sie sich gerne an unsere Filialen wenden oder uns telefonisch unter <a class="link primary" href="tel:+4921619060957">02161 - 90 60 957</a> (8-20 Uhr) erreichen.'
			}
		},
		protect: {
			title: 'Einkommensausfall absichern',
			subtitle: 'Mit Santander Protect können Sie Ihr monatliches Einkommen gegen eine Vielzahl von Risiken absichern.',
			body: {
				title: 'Deckungs- und Absicherungslaufzeit wählen',
				subtitle: 'Aufgrund Ihrer zuvor eingegebenen Gehaltsangaben haben wir hiermit Ihre Einkommenslücke berechnet, bei einer max. monatlichen Absicherung von 1.000 €. Die Deckungs- und Absicherungslaufzeit können Sie individuell anpassen.',
				runtime: {
					label: 'Laufzeit'
				},
				monthlyProtect: {
					label: 'Monatliche Absicherung'
				},
				selectTitle: 'Protect Tarif wählen',
				table: {
					row1: {
						title: 'Kurzzeitpflege, Elternzeit',
						popup: {
							headline1: 'Kurzzeitpflege',
							content1: '<p>Sie sorgen für den Menschen, der Ihnen wichtig ist. Wir sorgen für Ihr Einkommen</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der Kurzzeitpflege ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 6 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Die Pflege direkter und naher Verwandter ist mitversichert.</li></ul>',
							headline2: 'Elternzeit',
							content2: '<p>Nehmen Sie sich Zeit für Ihre Familie – ohne Sorgen um Ihr Einkommen</p><ul><li>Beispiel: Das Basiselterngeld beträgt in der Regel 65-67% des Nettoeinkommens. Bei einem Nettoeinkommen von 2000 € beträgt der Einkommensausfall während der Elternzeit ca. 660 € pro Monat.</li><li>Sie erhalten bis zu 6 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Adoptionen sind mitversichert.</li></ul>'
						}
					},
					row2: {
						title: 'Kurzarbeit, Arbeitslosigkeit, schwere Erkrankungen',
						popup: {
							headline1: 'Kurzarbeit',
							content1: '<p>Auch in schwierigen Zeiten bleibt Ihr Einkommen stabil</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der konjunkturellen Kurzarbeit ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 12 Monate 50% der vereinbarten monatlichen Leistung.</li><li>Auch bei befristeten Arbeitsverträgen.</li></ul>',
							headline2: 'Arbeitslosigkeit',
							content2: '<p>Fokussieren Sie sich auf Ihren nächsten Job – und nicht auf Ihre finanziellen Sorgen</p><ul><li>Planbares Einkommen trotz Arbeitslosigkeit: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der Arbeitslosigkeit ca. 800 € pro Monat.</li><li>Sie erhalten bis zu 12 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Arbeitnehmer und Angestellte im öffentlichen Dienst sind mitversichert.</li></ul>',
							headline3: 'Schwere Erkrankungen',
							content3: '<p>Konzentrieren Sie sich auf Ihre Genesung – ohne Geldsorgen</p><ul><li>Gilt nur für Beamte, Selbstständige und Rentner.</li><li>Versichert sind Krankheiten wie beispielsweise Krebs, Herzinfarkt, Schlaganfall, etc.</li><li>Sie erhalten bis zu 12 Monate 100% der vereinbarten monatlichen Leistung.</li></ul>'
						}
					},
					row3: {
						title: 'Arbeitsunfähigkeit',
						popup: {
							headline1: 'Arbeitsunfähigkeit',
							content1: '<p>Konzentrieren Sie sich auf Ihre Genesung - ohne Geldsorgen</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt der Einkommensausfall während der Arbeitsunfähigkeit ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 12 Monate 50% der vereinbarten monatlichen Leistung.</li><li>Ohne Gesundheitsfragen.</li><li>Psychische Erkrankungen sind mitversichert.</li></ul>'
						}
					},
					row4: {
						title: 'Unfalltod',
						popup: {
							headline1: 'Unfalltod',
							content1: '<p>Sorgen Sie vor – für Ihre Liebsten</p><ul><li>Hinterbliebene absichern mit einer einmaligen Versicherungsleistung ohne Wartezeit.</li><li>Die Versicherungsleistung beträgt das 50-fache des gewählten Leistungsbetrages (maximal 150.000 €).</li></ul>'
						}
					},
					standard: {
						title: 'Protect<br />Standard'
					},
					premium: {
						title: 'Protect<br />Premium',
						higlightLabel: 'Top Seller'
					},
					basic: {
						title: 'Protect<br />Basis'
					},
					noProtect: 'Ich will meinen Einkommensausfall nicht absichern'
				}
			}
		}
	},
	paymentProtectionInsurance: {
		title: 'Welchen Schutz möchten Sie?',
		subtitle:
			'Mit einer Ratenschutzversicherung brauchen Sie sich auch im Ernstfall keine Sorgen um die Rückzahlung Ihres Kredits zu machen.',
		loadingPageText:
			'Wir analysieren Ihre Daten. Das kann einige Sekunden dauern.',
		selectOne: 'Bitte wählen',
		openSidebarText: 'Zu allen Kreditdetails',
		completeProtection: {
			label: 'Komplettschutz',
			check1: 'Todesfall',
			check2: 'Unfalltod',
			check3: 'Arbeitsunfähigkeit',
			check4: 'Arbeitslosigkeit',
			popUp: {
				headline: 'Komplettschutz',
				subhead:
					'Finanzielle Absicherung im Todesfall, bei Unfalltod, Arbeitsunfähigkeit und Arbeitslosigkeit',
				content:
					'<strong>Todesfall:</strong> Übernahme der gemäß Tilgungsplan noch offenen Summe<br/><br/><strong>Unfalltod:</strong> Zusätzliche Zahlung der gemäß Tilgungsplan noch offenen Summe an die Hinterbliebenen<br/><br/><strong>Arbeitsunfähigkeit:</strong> Übernahme der versicherten Raten im Falle der vorrübergehenden Arbeitsunfähigkeit durch Unfall oder Krankheit<br/><br/><strong>Arbeitslosigkeit:</strong> Übernahme der versicherten Raten im Falle der unverschuldeten Arbeitslosigkeit<br/><br/>Der Versicherungsschutz besteht ohne Gesundheitsprüfung und beginnt nach einer Wartezeit von 3 Monaten ab Darlehensauszahlung (Ausnahme Unfalltod). Die Ratenschutzversicherung ist nicht Voraussetzung für die Gewährung des Darlehens und kann auch nach Abschluss unabhängig vom Darlehensvertrag mit einer Kündigungsfrist von einem Monat zum Schluss eines jeden Monats gekündigt werden.'
			}
		},
		standardProtection: {
			label: 'Standardschutz',
			check1: 'Todesfall',
			check2: 'Unfalltod',
			check3: 'Arbeitsunfähigkeit',
			check4: 'Arbeitslosigkeit',
			popUp: {
				headline: 'Standardschutz',
				subhead:
					'Finanzielle Absicherung im Todesfall, bei Unfalltod und Arbeitsunfähigkeit',
				content:
					'<strong>Todesfall:</strong> Übernahme der gemäß Tilgungsplan noch offenen Summe<br/><br/><strong>Unfalltod:</strong> Zusätzliche Zahlung der gemäß Tilgungsplan noch offenen Summe an die Hinterbliebenen<br/><br/><strong>Arbeitsunfähigkeit:</strong> Übernahme der versicherten Raten im Falle der vorrübergehenden Arbeitsunfähigkeit durch Unfall oder Krankheit<br/><br/>Der Versicherungsschutz besteht ohne Gesundheitsprüfung und beginnt nach einer Wartezeit von 3 Monaten ab Darlehensauszahlung (Ausnahme Unfalltod). Die Ratenschutzversicherung ist nicht Voraussetzung für die Gewährung des Darlehens und kann auch nach Abschluss unabhängig vom Darlehensvertrag mit einer Kündigungsfrist von einem Monat zum Schluss eines jeden Monats gekündigt werden.'
			}
		},
		basicProtection: {
			label: 'Basisschutz',
			check1: 'Todesfall',
			check2: 'Unfalltod',
			check3: 'Arbeitsunfähigkeit',
			check4: 'Arbeitslosigkeit',
			popUp: {
				headline: 'Basisschutz',
				subhead: 'Finanzielle Absicherung im Todesfall',
				content:
					'<strong>Todesfall:</strong> Übernahme der gemäß Tilgungsplan noch offenen Summe<br/><br/>Der Versicherungsschutz besteht ohne Gesundheitsprüfung und beginnt nach einer Wartezeit von 3 Monaten ab Darlehensauszahlung (Ausnahme Unfalltod). Die Ratenschutzversicherung ist nicht Voraussetzung für die Gewährung des Darlehens und kann auch nach Abschluss unabhängig vom Darlehensvertrag mit einer Kündigungsfrist von einem Monat zum Schluss eines jeden Monats gekündigt werden.'
			}
		},
		noRsv: {
			label: 'Keine Ratenschutzversicherung',
			short: 'Keine RSV',
			dontNeedAdditionalProtection: 'Ich brauche keinen Schutz.',
			popUp: {
				headline: 'Keine Ratenschutzversicherung',
				content:
					'Die Ratenschutzversicherung ist nicht Voraussetzung für die Gewährung der Kreditkarte, bietet Ihnen und Ihrer Familie jedoch im Falle des Todes (und ggf. der Arbeitsunfähigkeit sowie der Arbeitslosigkeit) ein hohes Maß an Sicherheit vor finanziellen Belastungen.<br/><br/>Sollte Sie keine weitere Absicherung haben und auch keine Absicherung wünschen, tragen Sie auch bei Eintreten unvorhersehbarer Ereignisse weiterhin alle Kosten selber. Bei weiteren Fragen können Sie sich gerne an unsere Filialen wenden oder uns telefonisch unter <a class="link primary" href="tel:+4921619060599">02161 - 90 60 599</a> erreichen.'
			}
		},
		externalInsurance: 'Haben Sie bereits eine andere Ratenschutzversicherung?'
	},
	termsAndConditions: {
		acceptTermsAndConditions: {
			description:
				'Ich habe Datenschutzhinweise, die Werbehinweise sowie die AGB und das Preisverzeichnis der Santander gelesen.',
			linkText: 'Bedingungen und Informationen zu Santander BestCredit'
		},
		submit: 'Jetzt Kredit anfragen'
	},
	thankYou: {
		title:
			'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.'
	},
	bcoResponseGreen: {
		title: 'Gute Neuigkeiten!',
		subtitle: 'Ihr Kredit wurde vorläufig genehmigt.',
		infoText:
			'Wir brauchen dafür nur noch die erforderlichen Nachweise und Ihren unterschriebenen Vertrag.',
		nextSteps: {
			title: 'Ihre letzten Schritte bis zur Auszahlung',
			subtitle: 'Nur noch wenige Schritte bis zur Auszahlung.',
			step1: 'Dokumente hochladen',
			step2: 'Identifikation und Unterschrift',
			step3: 'Auszahlung'
		},
		submitDocumentUpload: 'Jetzt Dokumente hochladen',
		submitIdentification: 'Jetzt Identifizieren'
	},
	identification: {
		subtitle:
			'Mit dem Ident Verfahren unseres Partners WebID Solutions können Sie sicher, einfach und bequem Ihre Identität bestätigen und digital unterschreiben.',
		kontoIdent: {
			title: 'Konto-Ident',
			subtitle: 'Identifizieren mit einer Überweisung von 1 Cent',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<p><strong>Identifizieren</strong></p><ol><li>Fotos von Ausweis und Gesicht machen</li><li>Log-in ins Online-Banking, Überweisung von 1 Cent bestätigen</li><li>TAN-Eingabe</li></ol><p><strong>Digital unterschreiben:</strong></p><ol><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol>'
		},
		videoIdent: {
			title: 'Video-Ident',
			subtitle: 'Videogespräch mit einem WebID-Agenten',
			list:
				'<li>Kurze Wartezeit</li><li>Von 7:00 bis 22:00 Uhr von überall möglich</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<p><strong>Identifizieren</strong></p><ol><li>Ausweis bereit halten</li><li>Videocall mit WebID-Agenten durchführen</li><li>TAN-Eingabe</li></ol><p><strong>Digital unterschreiben:</strong></p><ol><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol>'
		},
		digitalIdent: {
			title: 'Digitale Unterschrift',
			subtitle:
				'Personalausweis abfotografieren, Vertrag sichten und per TAN-Eingabe unterschreiben',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>'
		}
	},
	download: {
		contract: {
			application: 'Kreditantrag herunterladen',
			pdftitle: 'Kreditantrag.pdf'
		}
	},
	addressVerification: {
		title: 'Bitte bestätigen Sie Ihre Adresse.',
		subtitle:
			'Um den Vorgang abzuschließen benötigen wir von Ihnen noch eines der folgenden Dokumente: Meldebescheinigung, oder eine Rechnung für Telefon, Strom oder Gas.',
		submitExtension1: 'Kein Dokument zur Hand?',
		submitExtension2: 'Später hochladen'
	},
	addressSelection: {
		title: 'Welche Adresse ist korrekt?',
		subtitle:
			'Die von Ihnen angegebene Adresse stimmt nicht mit der Adresse aus dem Video-Ident Verfahren überein. Welche Adresse ist aktueller?',
		items: {
			webIdAddress: {
				title: 'Adresse in Ihrem Ausweis',
				subtitle: 'Bereits bestätigt. Direkte Kreditbeantragung.'
			},
			originalAddress: {
				title: 'Angegebene Adresse',
				subtitle: 'Erfordert einen weiteren Nachweis.'
			}
		}
	},
	addressVerificationPostponed: {
		title:
			'Wir haben Ihnen eine E-Mail geschickt. So können Sie jederzeit zurückkehren.',
		subtitle:
			'Wir können Ihren Antrag erst dann bearbeiten, wenn Sie alle benötigten Dokumente vollständig hochgeladen haben.'
	},
	activateLoan: {
		title: 'Sie haben Ihren Kreditantrag erfolgreich digital unterschrieben.',
		subtitle:
			'Herzlichen Glückwunsch! Ihr Kreditvertrag ist nun verbindlich abgeschlossen. Das Geld ist bald auf Ihrem Konto',
		button: 'Jetzt Kredit auszahlen'
	},
	loanSuccess: {
		title: 'Geschafft! Ihr Geld ist unterwegs.',
		subtitle:
			'Der Kredit steht zur Auszahlung bereit und wird in Kürze Ihrem Konto gutgeschrieben!',
		accountNr: 'Kreditkontonummer',
		button: 'Schließen'
	},
	creditDone: {
		title:
			'Geschafft! Nach einer finalen, positiven Bewertung ist das Geld bald auf Ihrem Konto.',
		button: 'Schließen'
	},
	wishAmount: {
		tooltip:
			'Der hier angezeigte Kreditbetrag beinhaltet lediglich die von Ihnen gewünschte Kreditsumme und keine eventuellen Ablösekredite.'
	},
	redemptionAmount: {
		tooltip:
			'Bitte geben Sie hier den Gesamtbetrag aller bereits bestehenden Kredite an, die Sie ablösen möchten.'
	},
	finalStepsTwoBorrowers: {
		title: 'Letzte Schritte zu Ihrem Kredit',
		subtitle:
			'Wir brauchen dafür nur noch die erforderlichen Nachweise und Ihre beiden Identifikationen.',
		subtitleSa:
			'Wir brauchen dafür nur noch die erforderlichen Nachweise und Identifikationen des zweiten Darlehensnehmers.',
		finished: 'Alles erledigt',
		continue: 'Weiter',
		documents: 'Dokumente',
		identification: 'Identifikation',
		signature: 'Unterschrift',
		open: 'offen',
		complete: 'Komplett',
		documentState: {
			none: 'Noch keine',
			missing: 'Weitere Dokumente nötig',
			complete: 'Komplett'
		},
		contractState: {
			notSigned: 'Noch nicht unterschrieben',
			signed: 'Unterschrieben'
		}
	},
	exitPopup: {
		title: 'BestCredit',
		subhead: 'Gehen Sie noch nicht!',
		description: 'Verwirklichen Sie Ihre Träume mit dem Santander BestCredit.<br />Schließen Sie noch heute bequem digital ab und das Geld ist schon bald auf Ihrem Konto.',
		continueButton: 'Fortfahren'
	}
};
